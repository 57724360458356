import { Action } from '@ngrx/store';
export const GRID_CELLS = 'GRID CELLS';
export const CELL_DETAILS = 'CELL DETAILS';
export const SELECTED_OPPORTUNITY = 'SELECTED OPPORTUNITY';
export const GET_COHORT_REQUEST = 'GET COHORT REQUEST';
export const GET_COHORT_SUCCESS = 'GET COHORT SUCCESS';
export const GET_COHORT_FAILED = 'GET COHORT FAILED';
export const RESET_OPPORTUNITIES = 'RESET_STATE_OPPORTUNITIES'

export const GET_WRITERS_REQUEST = 'GET WRITERS REQUEST'
export const GET_WRITERS_SUCCESS = 'GET WRITERS SUCCESS'
export const GET_WRITERS_FAILED = 'GET WRITERS FAILED'

export const GET_WRITER_HCP_REQUEST = 'GET WRITERS HCP REQUEST'
export const GET_WRITER_HCP_SUCCESS = 'GET WRITERS HCP SUCCESS'
export const GET_WRITER_HCP_FAILED = 'GET WRITERS HCP FAILED'

export class selectedCohortGridDetails implements Action {
  readonly type = GRID_CELLS;
  payload: any;

  constructor(selected: any) {
    this.payload = { ...selected };
  }
}

export class selectedCellDetails {
  readonly type = CELL_DETAILS;
  payload: any;

  constructor(selected: any) {
    this.payload = { ...selected };
  }
}

export class selectedOpportunity {
  readonly type = SELECTED_OPPORTUNITY;
  payload: any;

  constructor(selected: any) {
    this.payload = { ...selected };
  }
}

export class getCohortRequestAction {
  readonly type = GET_COHORT_REQUEST;
}

export class getCohortSuccessAction {
  readonly type = GET_COHORT_SUCCESS;
  payload: any;
  
  constructor(payload: any) {
    this.payload = {...payload}
  }
}

export class resetOpportunitiesState {
  readonly type = RESET_OPPORTUNITIES;
}

export class getWritersRequestAction {
  readonly type = GET_WRITERS_REQUEST;
}

export class getWritersSuccessAction {
  readonly type = GET_WRITERS_SUCCESS;
  payload: any;

  constructor(payload: any) {
      this.payload = { ...payload }
  }
}

export class getWriterHCPRequestAction {
  readonly type = GET_WRITER_HCP_REQUEST;
}

export class getWriterHCPSuccessAction {
  readonly type = GET_WRITER_HCP_SUCCESS;
  payload: any;

  constructor(payload: any) {
      this.payload = { ...payload }
  }
}