import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appScrollDetector]'
})
export class ScrollDetectorDirective implements AfterViewInit {

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngAfterViewInit() {
    this.detectAndApplyPadding();
  }

  detectAndApplyPadding() {
    const element = this.el.nativeElement;

    // Check if there is a vertical scroll
    if (element.scrollHeight > element.clientHeight) {
      // Apply padding if there is a vertical scroll
      this.renderer.setStyle(element, 'padding-right', '0.5rem'); // Adjust the padding value as needed
    }
  }

}
