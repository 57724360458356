<div class="p-4 card-wrapper">
    <div class="selection-title">
        {{data.header}}
        <span class="close" mat-dialog-close><mat-icon>close</mat-icon></span>
    </div>
    <div class="pt-3">
        <span class="selected-filter">{{data.message}}</span>
    </div>
    <mat-dialog-actions align="end" class="pt-3">
        <div *ngIf="data?.header == 'Reset cohort'">
            <button class="cancel-button mx-2" (click)="cancel()">
                <span class="cancel-cohort-label">Close</span>
            </button>
        </div>
        <div *ngIf="data?.header != 'Reset cohort'">
            <button class=" cancel-button mx-2" (click)="cancel()">
            <span class="cancel-cohort-label">No</span>
            </button>
            <button class="save-button" (click)="confirm()">
                <span class="save-cohort-label">Yes</span>
            </button>
        </div>
    </mat-dialog-actions>
</div>