<div class="px-4 py-4 h-100">
    <form class="d-flex flex-column justify-content-between h-100" [formGroup]="addEditForm">
        <div>
            <div class="row mb-2 d-flex flex-row align-items-center justify-content-between">
                <p class="mb-0 add-label fit-content">{{header}}</p>
                <span class="close fit-content" mat-dialog-close (click)="cancel()"><mat-icon>close</mat-icon></span>
            </div>
            <hr class="mb-4">
            <div class="pb-4">
                <label for="campaignName" class="pb-1">Campaign Name</label>
                <input spellcheck="false" type="text" placeholder="Enter Name" formControlName="name" required name ="name" class="form-control" autocomplete="off" >
                <mat-error *ngIf="isDuplicateName">Campaign Name already exists.</mat-error>
                <mat-error *ngIf="getFormValidity('name', 'required')">This field is required.</mat-error>
                <mat-error *ngIf="getFormValidity('name', 'pattern')  || getFormValidity('name', 'maxlength')">Leading/trailing spaces, special characters are not allowed & max limit is 256 characters</mat-error>
            </div>
            <div class="pb-4">
                <label for="campaignDescription" class="pb-1">Campaign Description</label>
                <textarea spellcheck="false" spellcheck="false" rows="3" placeholder="Enter Description" formControlName="description" required name="description" class="form-control"></textarea>
                <mat-error *ngIf="getFormValidity('description', 'required')">This field is required.</mat-error>
                <mat-error *ngIf="getFormValidity('description', 'pattern')  || getFormValidity('name', 'maxlength')">Leading/trailing spaces, special characters are not allowed & max limit is 1024 characters</mat-error>
            </div>
        </div>
        <div class="d-flex flex-row justify-content-end">
            <button class="save-btn" (click)="onSave()" [disabled]="addEditForm.get('name').value == '' || addEditForm.get('description').value == ''">
                <span class="save-btn-label">Save</span>
            </button>
        </div>
    </form>
</div>
