import { Component, OnInit, AfterViewInit, ElementRef, HostListener } from '@angular/core';
import { Renderer2 } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'i-a_reinvention_web';

  // ngOnInit(): void {
  //   document.ready(function() {
  //     $('[data-toggle="tooltip"]').tooltip();
  //   })
  // }

  // @HostListener("window:unload",["$event"])
  // clearLocalStorage(event){
  //     localStorage.clear();
  // }

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const tooltipElement = this.el.nativeElement.querySelector('[data-toggle="tooltip"]');
    if (tooltipElement) {
      tooltipElement.tooltip();
    }
  }
}
