import { Action } from '@ngrx/store';
export const GET_FILTERS_REQUEST = 'GET FILTERS REQUEST';
export const GET_FILTERS_SUCCESS = 'GET FILTERS SUCCESS';
export const GET_FILTERS_FAILED = 'GET FILTERS FAILED';

export const GET_SUMMARY_REQUEST = 'GET SUMMARY REQUEST';
export const GET_SUMMARY_SUCCESS = 'GET SUMMARY SUCCESS';
export const GET_SUMMARY_FAILED = 'GET SUMMARY FAILED';

export const GET_CHARTS_REQUEST = 'GET CHARTS REQUEST';
export const GET_CHARTS_SUCCESS = 'GET CHARTS SUCCESS';
export const GET_CHARTS_FAILED = 'GET CHARTS FAILED';

export class getFiltersRequestAction {
    readonly type = GET_FILTERS_REQUEST;
}

export class getFiltersSuccessAction {
    readonly type = GET_FILTERS_SUCCESS;
    payload: any;

    constructor(payload: any) {
        this.payload = { ...payload }
    }
}

export class getSummaryRequestAction {
    readonly type = GET_SUMMARY_REQUEST;
}

export class getSummarySuccessAction {
    readonly type = GET_SUMMARY_SUCCESS;
    payload: any;

    constructor(payload: any) {
        this.payload = { ...payload }
    }
}

export class getChartsRequestAction {
    readonly type = GET_CHARTS_REQUEST;
}

export class getChartsSuccessAction {
    readonly type = GET_CHARTS_SUCCESS;
    payload: any;

    constructor(payload: any) {
        this.payload = { ...payload }
    }
}