import { Component } from '@angular/core';
import { GlobalService } from 'src/app/services/global/global.service';

@Component({
  selector: 'app-primary-navigation',
  templateUrl: './primary-navigation.component.html',
  styleUrls: ['./primary-navigation.component.scss']
})
export class PrimaryNavigationComponent {

  noCampaigns : boolean = false;
  showSubNavigation : boolean = false;

  constructor(private globalservice : GlobalService){
    this.globalservice.allCampaignsDataChange.subscribe((obs) => {
    });
    this.globalservice.campaignClicked.subscribe((obs) => {
    })
  }

}
