import { Action } from '../actions';
import {
  GET_FILTERS_REQUEST,
  GET_FILTERS_SUCCESS,
  GET_FILTERS_FAILED,

  GET_SUMMARY_REQUEST,
  GET_SUMMARY_SUCCESS,
  GET_SUMMARY_FAILED,

  GET_CHARTS_REQUEST,
  GET_CHARTS_SUCCESS,
  GET_CHARTS_FAILED
} from '../actions/actions'

export interface FiltersReducerState {
  loading: boolean;
  loaded: boolean;
  getFiltersResponse: any;
}

export const initialStateGetFilters: FiltersReducerState = {
  loading: false,
  loaded: false,
  getFiltersResponse: {}
}

export interface SummaryReducerState {
  loading: boolean;
  loaded: boolean;
  getSummaryResponse: any;
}

export const initialStateGetSummary: SummaryReducerState = {
  loading: false,
  loaded: false,
  getSummaryResponse: {}
}

export interface ChartsReducerState {
  loading: boolean;
  loaded: boolean;
  getChartsResponse: any;
}

export const initialStateGetCharts: ChartsReducerState = {
  loading: false,
  loaded: false,
  getChartsResponse: {}
}

export function FiltersReducer(state = initialStateGetFilters, action: Action): FiltersReducerState {
  switch (action.type) {
    case GET_FILTERS_REQUEST: {
      return { ...state, loading: true }
    }
    case GET_FILTERS_SUCCESS: {
      const data = action.payload;
      return { ...state, loading: false, loaded: true, getFiltersResponse: data }
    }
    default:
      return state;
  }
}

export function SummaryReducer(state = initialStateGetSummary, action: Action): SummaryReducerState {
  switch (action.type) {
    case GET_SUMMARY_REQUEST: {
      return { ...state, loading: true }
    }
    case GET_SUMMARY_SUCCESS: {
      const data = action.payload;
      return { ...state, loading: false, loaded: true, getSummaryResponse: data }
    }
    default:
      return state;
  }
}

export function ChartsReducer(state = initialStateGetCharts, action: Action): ChartsReducerState {
  switch (action.type) {
    case GET_CHARTS_REQUEST: {
      return { ...state, loading: true }
    }
    case GET_CHARTS_SUCCESS: {
      const data = action.payload;
      return { ...state, loading: false, loaded: true, getChartsResponse: data }
    }
    default:
      return state;
  }
}

// selectors
export const getLoading = (st: FiltersReducerState) => st.loading;
export const getLoaded = (st: FiltersReducerState) => st.loaded;
export const getFilters = (st: FiltersReducerState) => st.getFiltersResponse;

export const getSummaryLoading = (st: SummaryReducerState) => st.loading;
export const getSummaryLoaded = (st: SummaryReducerState) => st.loaded;
export const getSummary = (st: SummaryReducerState) => st.getSummaryResponse;

export const getChartsLoading = (st: ChartsReducerState) => st.loading;
export const getChartsLoaded = (st: ChartsReducerState) => st.loaded;
export const getCharts = (st: ChartsReducerState) => st.getChartsResponse;