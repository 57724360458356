<nav class="h-100 primary-nav" id="side-rail">
    <div class="display-flex padding-0-1-1-1 flex-justify-center" id="primary-logo">
            <img src="./../../../../assets/images/zs-logo.png" class="pt-1">
    </div>

    <nav id="main-navigation" class="toolbar toolbar-vertical toolbar-labels-below ">
                <ng-container>
                        <a routerLink="/pages/current-performance" routerLinkActive="active">
                                <mat-icon svgIcon="current-performance"></mat-icon>
                                <label class="text-white">Current Performance</label>
                        </a>   
                </ng-container>
                <ng-container>
                        <a routerLink="/pages/opportunities" routerLinkActive="active">
                                <mat-icon svgIcon="opportunities"></mat-icon>
                                <label class="text-white">Opportunities</label>
                        </a>
                </ng-container>
                <ng-container>
                        <a routerLink="/pages/tactics" routerLinkActive="active">
                                <mat-icon svgIcon="tactics"></mat-icon>
                                <label class="text-white">Tactics</label>
                        </a>
                </ng-container>
                <ng-container>
                        <a routerLink="/pages/campaigns" routerLinkActive="active">
                                <mat-icon svgIcon="tactics"></mat-icon>
                                <label class="text-white">Campaigns</label>
                        </a>
                </ng-container>
    </nav>
</nav>
