import { Injectable,Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchDriver'
})

@Injectable()

export class SearchDriverPipe implements PipeTransform {
    transform(data: any[], find: string): any[] {
        if(!data) return [];
        if(!find) return data;
        find = find.toLowerCase();
        return search( data, find);
    }
}

const { isArray } = Array;

function search(entries: any[], search: string) {

  search = search.toLowerCase();

  return entries.filter(function (obj) {
    // const keys: string[] = Object.keys(obj);
    const keys: string[] = ['Driver', 'Percentage'];
    return keys.some(function (key) {
      const value = obj[key].toString();
      if (isArray(value)) {
        return value.some(v => {
          return v.toLowerCase().includes(search);
        });
      }
      else if (!isArray(value)) {
        return value.toLowerCase().includes(search);
      }
    })
  });
}