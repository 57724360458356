import { Component, Inject, Input, HostListener } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog'
import { GlobalService } from 'src/app/services/global/global.service';
import { selectedCampaignDetails } from 'src/app/store/global/actions/actions';
import * as fromStore from 'src/app/store';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';

@Component({
  selector: 'app-current-campaigns-dialog',
  templateUrl: './current-campaigns-dialog.component.html',
  styleUrls: ['./current-campaigns-dialog.component.scss']
})
export class CurrentCampaignsDialogComponent {

  campaignDetails : any;
  selectedCampaignDetails : any;
  isPublished: boolean = false;
  currentCampaignDetails: any = [];
  searchValue: string = "";
  filteredObjects: any;
  constructor(private dialogRef: MatDialogRef<CurrentCampaignsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private globalservice : GlobalService, private store: Store<fromStore.RootReducerState>, @Inject(MAT_DIALOG_DATA) public dialogInput: any,){
    this.campaignDetails = JSON.parse(localStorage.getItem('currentCampaignDetails'));
    this.filteredObjects = JSON.parse(localStorage.getItem('currentCampaignDetails'));
    this.selectedCampaignDetails = JSON.parse(localStorage.getItem('selectedCampaignDetails'));
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.isPublished = this.dialogInput?.isPublished;
    this.currentCampaignDetails = this.dialogInput?.currentCampaignDetails;    
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close(false);
  }

  cancel(): void {   
    this.dialogRef.close(false);
  }

  callSearchIfSearchBoxEmpty() {
    // if (this.searchValue == '') {
      // call function to search for empty term      
        this.searchTerm();
    // }
  }

  searchTerm() {           
    if (this.searchValue.trim() === '') {
      this.campaignDetails = JSON.parse(localStorage.getItem('currentCampaignDetails'));
    } else {
      this.campaignDetails.campaigns = this.filteredObjects.campaigns.filter(obj =>
        obj.CampaignName.toLowerCase().includes(this.searchValue.toLowerCase())
        );        
    }
  }

  setSelectedCampaign(id: string, name: string){
    this.globalservice.onCampaignClicked(true);
    let refreshIdLS = JSON.parse(localStorage.getItem("currentCampaignDetails"));
    refreshIdLS = refreshIdLS['refreshId']
    localStorage.setItem("selectedCampaignDetails", JSON.stringify({
      id : id,
      name : name,
      refresh : refreshIdLS
    }));
    this.store.dispatch(new selectedCampaignDetails({ payload: {
      id : id,
      name : name,
      refresh: refreshIdLS
    }}));
    fromStore.clearAllStatesInStore(this.store);
    this.globalservice.onChangeSelectedCampaign(true);
    this.selectedCampaignDetails = JSON.parse(localStorage.getItem('selectedCampaignDetails'));
    this.dialogRef.close('success');
  }

  /**
  * @remarks
  * add a new campaign and API call to backend
  * open a dailog - add campaign name and description
  */
  addCampaign(): void {
    this.dialogRef.close(true);
  }

}
