<div class="px-3">
    <div class="row d-flex flex-row align-items-center justify-content-between px-4 py-4 pb-0">
        <!-- <p class="current-campaign-heading mb-0 fit-content">Current Campaigns</p> -->
        <div class="col-12 d-flex align-items-center">
            <p class="current-campaign-heading mb-0 fit-content pe-3">Current Campaigns</p>
        
            <div class="search-box">
                <mat-form-field appearance="fill">
                    <mat-icon matPrefix svgIcon="search" class="search-icon"></mat-icon>
                    <input matInput placeholder="Search campaigns" [(ngModel)]="searchValue" (keyup)="callSearchIfSearchBoxEmpty()">
                    <mat-icon role="button" class="search-close-icon" *ngIf="searchValue" (click)="searchValue=''; searchTerm()" matSuffix>close</mat-icon>
                </mat-form-field>
            </div>
        
            <div class="d-flex align-items-center ms-auto">
                <button class="select-campaign-btn" [disabled]="isPublished && currentCampaignDetails.length != 0" (click)="addCampaign()"><span class="select-campaign-btn-label">Add new Campaign</span></button>
                <span class="ps-2 d-flex align-items-center close fit-content" mat-dialog-close (click)="cancel()"><mat-icon>close</mat-icon></span>
            </div>
        </div>
        
        
    </div>
    <hr>
    <div class="row px-4 py-4 pt-0">
        <div *ngFor="let ele of campaignDetails.campaigns" class="col-sm-4 mb-3" (click) = "setSelectedCampaign(ele.CampaignId, ele.CampaignName)">
            <mat-card [id]="ele.CampaignId" [ngClass]="selectedCampaignDetails.id == ele.CampaignId ? 'active-card px-4 py-4 campaign-card' : 'px-4 py-4 campaign-card'"
                role="button">
                <mat-card-content class="px-0 py-0 overflow-none">
                    <div class="row d-flex flex-column justify-content-between align-items-center gap-2">
                        <p class="campaign-name mb-2 fit-content">{{ele.CampaignName}}</p>
                        <p class="campaign-meta-data-label fit-content">{{ele.LastModifiedOn}}</p>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div *ngIf="campaignDetails.campaigns.length == 0" class="d-flex justify-content-center">
            <p class="campaign-name mb-2 fit-content">No campaigns found.</p>
        </div>
    </div>
</div>