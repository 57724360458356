import { Component, Inject, HostListener } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as fromStore from 'src/app/store';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import { TacticsApiService } from 'src/app/services/tactics/tactics.api.service';
import { currentCampaignDetails } from 'src/app/store/global/actions/actions';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-add-campaign',
  templateUrl: './add-campaign.component.html',
  styleUrls: ['./add-campaign.component.scss']
})
export class AddCampaignComponent {

  addCampaignForm : FormGroup;
  isDuplicateName : boolean = false;
  campaignName : string = "";
  originalName : string = "";
  campaignDescription : string = "";
  header : string= "Add Campaign";
  existingCampaigns : any;
  addEditForm: FormGroup;

  constructor(private store: Store<fromStore.RootReducerState>, private apiService : TacticsApiService, private dialogRef: MatDialogRef<AddCampaignComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder){
    if(this.data.isEdit){
      this.header = "Edit Campaign";
      this.originalName = this.data.name;
      // this.campaignDescription = this.data.desc;
    }
    this.existingCampaigns = this.data.existing;
    dialogRef.disableClose = true;
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.initializeAddEditForm();     
  }

  cancel(): void {   
    this.dialogRef.close(false);
  }

  confirm(): void {
    this.dialogRef.close(true)
  }

  /**
   * Initialze form for add and edit
   */
  initializeAddEditForm(): void{ 
    this.addEditForm = this.formBuilder.group({
      name: new FormControl(this.data?.name || '', [Validators.required, Validators.maxLength(256), Validators.pattern('^(?! )[a-zA-Z0-9\-_ ]*(?<! )$')]),
      description : new FormControl(this.data?.desc || '', [Validators.required, Validators.maxLength(1024), Validators.pattern('^(?! )[a-zA-Z0-9\-_ ]*(?<! )$')])
    })
  }

  /**
   * Check if form is valid and touched
   */
  getFormValidity(fieldName: string,  validator: string): boolean {        
    return this.addEditForm.controls[fieldName]?.errors?.[validator] && this.addEditForm.controls[fieldName].touched;
  }

  onSave(){
    let request;
    let response;
    let campaignDetails = JSON.parse(localStorage.getItem('currentCampaignDetails'));
    this.store.select(fromStore.getCampaignState).subscribe((obs) => {
      //api to save a campaign
      request = {
          Request: {
              RefreshId: obs && obs['payload'] ? obs['payload']['refreshId'] : campaignDetails['refreshId'],
              CampaignId: "", 
              CampaignName: this.addEditForm.value?.name,
              CampaignDescription: this.addEditForm.value?.description
          }
      }
    });
    if(this.data.isEdit){
      request['Request']['CampaignId'] = this.data.id;
      if (this.originalName !== this.addEditForm.value?.name) {
        if(this.existingCampaigns.includes(this.addEditForm.value?.name)){
          this.dialogRef.close("Campaign name already exists");
        }
      }
    }
    if(this.existingCampaigns.includes(this.addEditForm.value?.name) && !this.data.isEdit){
      this.dialogRef.close("Campaign name already exists");
    }
    else{
      this.apiService.addCampaign(request).subscribe({
        next: (res) => {
          if (res['Response']['ResponseMetadata']['Status'] == 'S') {
            this.dialogRef.close("success");
          }
        },
        error: (err) => {
          this.dialogRef.close("error");
        }
      });
    }
  }

}
