import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from './material/material.module'
import { HttpClientModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as c from './index';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { AddNumberSuffixPipe } from './pipes/add-number-suffix.pipe';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AddCampaignComponent } from './components/add-campaign/add-campaign.component';
import { PublishCampaignComponent } from './components/publish-campaign/publish-campaign.component';
import { ProgressiveChartComponent } from './components/progressive-chart/progressive-chart.component';
import { CurrentCampaignsDialogComponent } from './components/current-campaigns-dialog/current-campaigns-dialog.component';
import { RedirectPopupComponent } from './components/redirect-popup/redirect-popup.component';
import { ScrollDetectorDirective } from './directives/scroll-detector.directive';

const COMMON_COMPONENT = [
  c.BrandPerformanceSummaryCardComponent,
  c.BannerComponent,
  c.LoaderComponent,
  AddNumberSuffixPipe,
  ProgressiveChartComponent,
  AddCampaignComponent,
  ConfirmationComponent,
  PublishCampaignComponent
];

const COMMON_MODULE = [
  NgxSkeletonLoaderModule
];

const PIPES = [
  c.FilterPipe,
];

@NgModule({
  declarations: [
    ...COMMON_COMPONENT,
    ...PIPES,
    CurrentCampaignsDialogComponent,
    RedirectPopupComponent,
    ScrollDetectorDirective
  ],
  imports: [
    CommonModule,
    MaterialModule,
    HttpClientModule,
    NgSelectModule,
    FormsModule, 
    ReactiveFormsModule,
    ...COMMON_MODULE
  ],
  exports: [
    MaterialModule,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    ...COMMON_COMPONENT,
    ...COMMON_MODULE,
    ...PIPES
  ],
  providers: [c.SearchDriverPipe],
})
export class SharedModule { }
