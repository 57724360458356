import { Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class TacticsApiService {

  baseApiUrl: string = environment.apiUrl;
  
  public selectedCohortInformation = new Subject<any>();
  public editTacticsForDriver = new Subject<any>();
  public deleteTacticsForDriver = new Subject<any>();

  constructor(private http: HttpClient) { }

  getAllCohortsForTactics(data: any = {}): Observable<any[]> {
    return this.http.post<any>(`${this.baseApiUrl}/tactics/getTacticCohort`, data);
  }

  saveAllBaselineMessages(data: any): Observable<any> {
    // return this.http.post<any>(`${this.baseApiUrl}/tactics/submitBeliefMessage`, data);
    return this.http.post<any>(`${this.baseApiUrl}/tactics/editTactic`, data);
  }

  getBeliefsForSelectedCohort(data: any): Observable<any> {
    return this.http.post<any>(`${this.baseApiUrl}/tactics/getBeliefs`, data);
  }

  getBiasAndChannelData(data: any): Observable<any> {
    return this.http.post<any>(`${this.baseApiUrl}/tactics/getHCPBiasCount`, data);
  }

  selectCohortCardAndRedirect(event){
    this.selectedCohortInformation.next(event);
  }

  oneditTacticsForDriver(event){
    this.editTacticsForDriver.next(event);
  }

  ondeleteTacticsForDriver(event){
    this.deleteTacticsForDriver.next(event);
  }

  getHCPData(data: any): Observable<any> {
    return this.http.post<any>(`${this.baseApiUrl}/getHCP`, data);
  }

  getDriverSummaryCardData(data: any): Observable<any> {
    return this.http.post<any>(`${this.baseApiUrl}/driver_summary/getDriverSummaryData`, data);
  }

  deleteTacticsForSelectedDriver(data: any): Observable<any> {
    return this.http.post<any>(`${this.baseApiUrl}/tactics/deleteTactic`, data);
  }

  editTacticsForSelectedDriver(data: any): Observable<any> {
    return this.http.post<any>(`${this.baseApiUrl}/tactics/editTactic`, data);
  }

  getCurrentCampaignDetails(): Observable<any>{
    return this.http.get<any[]>(`${this.baseApiUrl}/campaigns/getCampaigns`);
  }

  addCampaign(data: any): Observable<any> {
    return this.http.post<any>(`${this.baseApiUrl}/campaigns/addEditCampaign`, data);
  }

  publishCampaign(data: any): Observable<any> {
    return this.http.post<any>(`${this.baseApiUrl}/campaigns/publishCampaign`, data);
  }

  getCohortDrivers(data: any): Observable<any> {
    return this.http.post<any>(`${this.baseApiUrl}/tactics/getCohortDrivers`, data);
  }

  getAllWritersCohortsBeliefs(data: any): Observable<any> {
    return this.http.post<any>(`${this.baseApiUrl}/tactics/getAllWritersCohortsBeliefs`, data);
  }
}
