import { Component } from '@angular/core';
import { SessionExpiredService } from '../services/global/session-expired.service';
import { MatDialog } from '@angular/material/dialog';
import { RedirectPopupComponent } from '../shared/components/redirect-popup/redirect-popup.component';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent {

  showSessionExpiredPopup = false;

  constructor(private sessionExpiredService: SessionExpiredService, public dialog: MatDialog) { }

  ngOnInit() {
    // this.sessionExpiredService.sessionExpiredPopup.subscribe((show) => {
    //   this.showSessionExpiredPopup = show;
    //   if(show) {
    //     this.dialog.open(RedirectPopupComponent, {          
    //       width: '650px',
    //       hasBackdrop: true,
    //       backdropClass: 'dialog-backdrop',
    //       disableClose: true,
    //     });
    //   }
    // });
  }

  onClose() {
    this.showSessionExpiredPopup = false;
  }
}
