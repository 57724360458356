<div class="banner-layout" *ngIf="alert">
    <div class="message-layout">
        <label [ngClass]="messageType">
            <span class="success-content"><i class="bi bi-check-circle-fill pe-2"></i> Success!</span>
            <span class="error-content"><i class="bi bi-x-circle-fill pe-2"></i>Error!</span>
            <span class="warning-content"><i class="bi bi-exclamation-circle-fill pe-2"></i>Warning!</span>
        </label>
        <label class="message">{{messageText}}</label>
    </div>
    <mat-icon role="button" class="d-flex text-white justify-content-end me-3" (click)="closeAlert()">close</mat-icon>
</div>