import { Action, ActionReducerMap, createSelector } from '@ngrx/store';
import * as fromOpportunities from './opportunities/reducers/reducers';
import * as fromTactics from './tactics/reducers/reducers';
import * as fromGlobal from './global/reducers/reducers';
import { getCohortSuccessAction, resetOpportunitiesState, selectedCellDetails, selectedCohortGridDetails, selectedOpportunity } from './opportunities/actions/actions';
import { allChannelData, allSelectedBias, allWritersAndCohorts, allWritersAndCohortsSuccess, baselineMessagesForDrivers, resetTacticsState, selectedBeliefList, selectedCohortDetails } from './tactics/actions/actions';
import * as fromPerformance from './current-performance/reducers/reducers';

/**
 * Root Reducer to include reducers from all modules
 */
export interface RootReducerState {
    //opportunities
    cohortsReducer: fromOpportunities.CohortsReducerState,
    selectedOpportunity: fromOpportunities.SelectedOpportunityIdReducerState,
    selectedCells: fromOpportunities.CohortsGridReducerState,
    cellDetails: fromOpportunities.CellDetailsReducerState,
    writerDetails: fromOpportunities.WritersReducerState,
    writerHCPDetails: fromOpportunities.WriterHCPReducerState,

    //tactics
    allWritersCohorts: fromTactics.AllWriterCohortsState,
    activeCohorts: fromTactics.ActiveCohortsState,
    allWriters: fromTactics.WritersState,
    allBias: fromTactics.AllBiasState,
    channels: fromTactics.ChannelsState,
    baselineMessages: fromTactics.BaselineMessageState,
    beliefs: fromTactics.BeliefsState,
    driverSummary: fromTactics.DriverSummaryReducerState,

    //global
    campaignData: fromGlobal.currentCampaigns,
    selectedCampaign: fromGlobal.selectedCampaigns,

    //current-performance
    filtersReducer: fromPerformance.FiltersReducerState,
    summaryReducer: fromPerformance.SummaryReducerState,
    chartsReducer: fromPerformance.ChartsReducerState
}


export const rootReducer: ActionReducerMap<RootReducerState> = {
    cohortsReducer: fromOpportunities.CohortsReducer,
    selectedOpportunity: fromOpportunities.OpportunityIdReducer,
    selectedCells: fromOpportunities.cohortsGridReducer,
    cellDetails: fromOpportunities.cellDetailsReducer,
    writerDetails: fromOpportunities.WritersReducer,
    writerHCPDetails: fromOpportunities.WriterHCPReducer,

    allWritersCohorts: fromTactics.allWritersCohortsReducer,
    activeCohorts: fromTactics.cohortsReducer,
    allWriters: fromTactics.writersReducer,
    allBias: fromTactics.biasReducer,
    channels: fromTactics.channelsReducer,
    baselineMessages: fromTactics.baselineMessageReducer, 
    beliefs: fromTactics.beliefsReducer,
    driverSummary: fromTactics.DriverSummaryReducer,

    //global
    campaignData: fromGlobal.currentCampaignsData,
    selectedCampaign: fromGlobal.selectedCampaignsData,

    //current-performance
    filtersReducer: fromPerformance.FiltersReducer,
    summaryReducer: fromPerformance.SummaryReducer,
    chartsReducer: fromPerformance.ChartsReducer


}

export const getCohortsState = (state: RootReducerState) => state.cohortsReducer;
export const getOpportunityId = (state: RootReducerState) => state.selectedOpportunity;
export const getSelectedCells = (state: RootReducerState) => state.selectedCells;
export const getCellDetails = (state: RootReducerState) => state.cellDetails;
export const getWritersDetails = (state: RootReducerState) => state.writerDetails;
export const getWriterHCPDetails = (state: RootReducerState) => state.writerHCPDetails;

export const getAllWritersCohorts = (state: RootReducerState) => state.allWritersCohorts;
export const getActiveCohorts = (state: RootReducerState) => state.activeCohorts;
export const getAllWriters = (state: RootReducerState) => state.allWriters;
export const getAllBias = (state: RootReducerState) => state.allBias;
export const getChannels = (state: RootReducerState) => state.channels;
export const getBaselineMessages = (state: RootReducerState) => state.baselineMessages;
export const getBeliefs = (state: RootReducerState) => state.beliefs;
export const getDriverSummary = (state: RootReducerState) => state.driverSummary;

export const getCampaigns = (state: RootReducerState) => state.campaignData;
export const getSelectedCampaigns = (state: RootReducerState) => state.selectedCampaign;

export const getFilters = (state: RootReducerState) => state.filtersReducer;
export const getSummaryState = (state: RootReducerState) => state.summaryReducer;
export const getChartsState = (state: RootReducerState) => state.chartsReducer;

/**
 * Selectors for Opportunities module
 */
export const getCohortsLoadedState = createSelector(getCohortsState, fromOpportunities.getLoaded);
export const getCohortsLoadingState = createSelector(getCohortsState, fromOpportunities.getLoading);
export const getCohortsResponseState = createSelector(getCohortsState, fromOpportunities.getCohorts);
export const getSelectedOpportunityId = createSelector(getOpportunityId, fromOpportunities.getSelectedOpportunity);
export const getSelectedCellsState = createSelector(getSelectedCells, fromOpportunities.getCohortsGrid);
export const getCellDetailsState = createSelector(getCellDetails, fromOpportunities.getCellDetails);

export const getWritersState = createSelector(getWritersDetails, fromOpportunities.getWriters);
export const getWritersLoadedState = createSelector(getWritersDetails, fromOpportunities.getWritersLoaded);
export const getWritersLoadingState = createSelector(getWritersDetails, fromOpportunities.getWritersLoading);

export const getWriterHCPState = createSelector(getWriterHCPDetails, fromOpportunities.getWriterHCP);
export const getWriterHCPLoadedState = createSelector(getWriterHCPDetails, fromOpportunities.getWriterHCPLoaded);
export const getWriterHCPLoadingState = createSelector(getWriterHCPDetails, fromOpportunities.getWriterHCPLoading);

/**
 * Selectors for Tactics module
 */
export const getAllWritersAndCohorts = createSelector(getAllWritersCohorts, fromTactics.getAllWritersCohorts);
export const getAllWritersAndCohortsLoadingState = createSelector(getAllWritersCohorts, fromTactics.getAllWritersLoading);
export const getAllWritersAndCohortsLoadedState = createSelector(getAllWritersCohorts, fromTactics.getAllWritersLoaded);
export const getActiveCohortsState = createSelector(getActiveCohorts, fromTactics.getActiveCohorts);
export const getAllWritersState = createSelector(getAllWriters, fromTactics.getAllWriters);
export const getAllBiasState = createSelector(getAllBias, fromTactics.getAllBias);
export const getChannelsState = createSelector(getChannels, fromTactics.getChannels);
export const getBeliefsState = createSelector(getBeliefs, fromTactics.getBeliefs);
export const getBaselineMessageState = createSelector(getBaselineMessages, fromTactics.getBaselineMessage);

export const getDriverSummaryState = createSelector(getDriverSummary, fromTactics.getDriverSummary);
export const getDriverSummaryLoadedState = createSelector(getDriverSummary, fromTactics.getDriverSummaryLoaded);
export const getDriverSummaryLoadingState = createSelector(getDriverSummary, fromTactics.getDriverSummaryLoading);

/**
 * Selectors for Campaign module
 */
export const getCampaignState = createSelector(getCampaigns, fromGlobal.getAllCurrentCampaigns);
export const getSelectedCampaignState = createSelector(getSelectedCampaigns, fromGlobal.getSelectedCampaigns);


/**
 * Selectors for Current performance
 */
export const getFiltersState = createSelector(getFilters, fromPerformance.getFilters);
export const getSummaryLoadedState = createSelector(getSummaryState, fromPerformance.getSummaryLoaded);
export const getSummaryLoadingState = createSelector(getSummaryState, fromPerformance.getSummaryLoading);
export const getSummaryResponseState = createSelector(getSummaryState, fromPerformance.getSummary);

export const getChartsLoadedState = createSelector(getChartsState, fromPerformance.getChartsLoaded);
export const getChartsLoadingState = createSelector(getChartsState, fromPerformance.getChartsLoading);
export const getChartsResponseState = createSelector(getChartsState, fromPerformance.getCharts);

export function clearAllStatesInStore(store): void{
    //clearing local storage
    localStorage.removeItem("selectedCohortDetails");
    localStorage.removeItem("allWritersAndCohorts");
    localStorage.removeItem("selectedCohortDetails");
    localStorage.removeItem("selectedBeliefList");

    //clearing selectors
    store.dispatch(new resetTacticsState());
    store.dispatch(new resetOpportunitiesState());

    //clearing store
    store.dispatch(new selectedCellDetails({
            accountType: [],
            speciality: [],
            region: [],
            potentialHcps: 0,
            potentialPatients: 0
        })
    );
    store.dispatch(new selectedCohortGridDetails({
            xMin: undefined,
            xMax: undefined,
            yMin: undefined,
            yMax: undefined,
            color: undefined,
        })
    );
    store.dispatch(new selectedOpportunity({
        writerId: ""
    }))
    store.dispatch(new selectedCohortDetails({
        id: "",
        cohortMetaData: {},
        cohortBeliefsData: [],
        beliefsType: [],
        convertedBeliefsData: []
    }))
    store.dispatch(new selectedBeliefList({
        ids: []
    }));
    store.dispatch(new baselineMessagesForDrivers({
        messages: []
    }));
    store.dispatch(new allSelectedBias({
        all: []
    }));
    store.dispatch(new allWritersAndCohorts({
        all: [],
        id: "",
        ischanged: true
    }));
    store.dispatch(new allChannelData({
        all: []
    }));
    store.dispatch(new allWritersAndCohortsSuccess({}));
    store.dispatch(new getCohortSuccessAction({}));
}