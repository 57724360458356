import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { selectedCampaignDetails } from 'src/app/store/global/actions/actions';
import * as fromStore from 'src/app/store';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})

export class GlobalService {

  public selectedCampaignIdName = new Subject<any>();
  public activatedURLofModule = new Subject<any>();
  public allCampaignsDataChange = new Subject<any>();
  public campaignClicked = new Subject<any>();

  constructor(private store: Store<fromStore.RootReducerState>) { }

  onChangeSelectedCampaign(event){
    this.selectedCampaignIdName.next(event);
  }

  changeURL(event){
    this.activatedURLofModule.next(event);
  }

  onChangeInCampaignNumber(event){
    this.allCampaignsDataChange.next(event);
  }

  onCampaignClicked(event){
    this.campaignClicked.next(event);
  }

  setLastModifiedCampaignAsSelected(allCampaigns : any[]): void{
    let fromLS = JSON.parse(localStorage.getItem("selectedCampaignDetails"));
    let refreshIdLS = JSON.parse(localStorage.getItem("currentCampaignDetails"));
    refreshIdLS = refreshIdLS['refreshId']
    if(fromLS == undefined || fromLS == null || Object.keys(fromLS).length == 0 || fromLS['id'] == ""){
      if(allCampaigns.length > 0){
        let tempCampaigns = [...allCampaigns];
        //@ts-ignore
        tempCampaigns = tempCampaigns.sort((a,b) => new Date (b.LastModifiedOn) - new Date (a.LastModifiedOn));
        let selectedCampaignNew = tempCampaigns[0];
        let id = selectedCampaignNew['CampaignId'];
        let name = selectedCampaignNew['CampaignName'];
        localStorage.setItem("selectedCampaignDetails", JSON.stringify({
          id : id,
          name : name,
          refresh : refreshIdLS
        }));
        this.store.dispatch(new selectedCampaignDetails({ payload: {
          id : id,
          name : name,
          refresh : refreshIdLS
        }}));
        this.onChangeSelectedCampaign(true);
      }
    }
  }

}
