import { Component, Inject, HostListener } from '@angular/core';
import * as fromStore from 'src/app/store';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import { TacticsApiService } from 'src/app/services/tactics/tactics.api.service';
import { currentCampaignDetails } from 'src/app/store/global/actions/actions';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-publish-campaign',
  templateUrl: './publish-campaign.component.html',
  styleUrls: ['./publish-campaign.component.scss']
})
export class PublishCampaignComponent {

  constructor(private store: Store<fromStore.RootReducerState>, private apiService : TacticsApiService, private dialogRef: MatDialogRef<PublishCampaignComponent>, @Inject(MAT_DIALOG_DATA) public data: any){
    dialogRef.disableClose = true;
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  cancel(): void {   
    this.dialogRef.close(false);
  }

  publishCampaign(): void {
    this.dialogRef.close(true)
  }

}
