import { Action } from '../actions';
import { CURRENT_CAMPAIGN, SELECTED_CAMPAIGN } from '../actions/actions';

  export interface currentCampaigns {
    campaignData : {
        campaigns : any[],
        refreshId : string,
        historic : any[]
    }
  }

  export interface selectedCampaigns {
    selectedCampaign : {
        id : string,
        name : string
    }
  }
  
  export const initialStateCurrentCampaigns: currentCampaigns = {
    campaignData : {
        campaigns : [],
        refreshId : "",
        historic : []
    }
  };

  export const initialStateSelectedCampaigns: selectedCampaigns = {
    selectedCampaign : {
      id : "",
      name : ""
    }
  };
  
  export function currentCampaignsData(state = initialStateCurrentCampaigns, action: Action): currentCampaigns {
    switch (action.type) {
      case CURRENT_CAMPAIGN:
        return { ...state,  campaignData : action.payload};
      default:
        return state;
    }
  }

  export function selectedCampaignsData(state = initialStateSelectedCampaigns, action: Action): selectedCampaigns {
    switch (action.type) {
      case SELECTED_CAMPAIGN:
        return { ...state, selectedCampaign : action.payload};
      default:
        return state;
    }
  }

//selectors
export const getAllCurrentCampaigns = (st: currentCampaigns) => st.campaignData;
export const getSelectedCampaigns = (st: selectedCampaigns) => st.selectedCampaign;
