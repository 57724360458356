import { Component, SimpleChanges } from '@angular/core';
import * as fromStore from 'src/app/store';
import { Store } from '@ngrx/store';
import { GlobalService } from 'src/app/services/global/global.service';
import { AuthService } from 'src/app/services/global/auth.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  selectedCampaignName : string;
  refreshDate : any;
  refreshId : string;
  showCampaignName : boolean = true;
  showRefreshDate: boolean = false;
  userId: string;
  loaded: boolean = false;

  constructor(private store: Store<fromStore.RootReducerState>, private globalService : GlobalService, private authService: AuthService){
    this.refreshCampaignName();
    this.getallCampaignDetails();
    this.globalService.selectedCampaignIdName.subscribe((obs) => {
      this.refreshCampaignName();
    });
    this.globalService.activatedURLofModule.subscribe((obs) => {
      let routeParams = obs.split("/");
      routeParams = routeParams.length > 0 ? routeParams[2] : ""
      if(routeParams == 'current-performance' || routeParams == 'campaigns'){
        this.showCampaignName = false;
        this.showRefreshDate = true;
      }
      else{
        this.showCampaignName = true;
        this.showRefreshDate = false;
      }
    });
  }

  refreshCampaignName(){
    let selectedCampaignDetails = JSON.parse(localStorage.getItem('selectedCampaignDetails'));
    this.store.select(fromStore.getSelectedCampaignState).subscribe((data) => {
      if(data?.id == '' || data?.id == null || data?.id == undefined){
        if(selectedCampaignDetails?.id == null || selectedCampaignDetails?.id == '' || selectedCampaignDetails?.id == undefined) {
        }
        else{
          this.selectedCampaignName = selectedCampaignDetails['name'];
        }
      }
      else this.selectedCampaignName = data['payload']['name'];
    });
  }

  getallCampaignDetails(){
    const campaignDetailsState = this.store.select(fromStore.getCampaignState);
    let campaignDetails = JSON.parse(localStorage.getItem('currentCampaignDetails'));
    campaignDetailsState.subscribe((data) => {
      if(data['payload'] && data['payload']['date']){
        this.refreshDate = data['payload'] ? data['payload']['date'] : '';
      }
      else{
        this.refreshDate = campaignDetails ? campaignDetails['refreshId'] : '';
      }
    });
  }

  ngOnInit(){
    this.userId = sessionStorage.getItem('activeSessionId');
    this.loaded = true;
    // this.authService.getUserDetails().subscribe({
    //   next: (response) => {
    //     this.userId = response?.Response?.UserId;
    //     this.loaded = true;
    //   },
    //   error: (err) => {
    //     this.loaded = true;
    //     console.log("Error in getUserDetails", err);
    //   }
    // })
  }

  myAccount(): void {
    window.location.href = environment.idmMyAccount;
  }

  logout(): void {
    sessionStorage.removeItem('activeSessionId');
    sessionStorage.removeItem('currentBrandId');
    localStorage.clear();
    window.location.href = environment.idmLogoffUrl;
  }

}
