import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SplashComponent } from './splash/splash.component';
import { ForbiddenPageComponent } from './forbidden-page/forbidden-page.component';
import { SessionGuard } from '@shared';

const routes: Routes = [
  {
    path:'',
    redirectTo: 'welcome',
    pathMatch: 'full'
  },
  {
    path:'pages',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    canActivate: [SessionGuard]
  },
  {
    path: 'welcome',
    component: SplashComponent
  },
  {
    path: 'forbidden',
    component: ForbiddenPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
