import { Action } from '@ngrx/store';
export const ACTIVE_COHORT = 'ACTIVE COHORT';
export const BASELINE_MESSAGES = 'BASELINE_MESSAGES';
export const SELECTED_BELIEFS = 'SELECTED BELIEFS';
export const ALL_WRITERS = 'ALL WRITERS';
export const ALL_WRITERS_REQUEST = 'ALL WRITERS REQUEST';
export const ALL_WRITERS_COHORTS = 'ALL WRITERS COHORTS';
export const ALL_WRITERS_COHORTS_REQUEST = 'ALL WRITERS COHORTS REQUEST';
export const ALL_CHANNELS = 'ALL CHANNELS';
export const BIAS_SELECTED = 'BIAS SELECTED';
export const RESET_TACTICS = 'RESET_STATE_TACTICS'

export const GET_DRIVER_SUMMARY_REQUEST = 'GET DRIVER SUMMARY REQUEST'
export const GET_DRIVER_SUMMARY_SUCCESS = 'GET DRIVER SUMMARY SUCCESS'
export const GET_DRIVER_SUMMARY_FAILED = 'GET DRIVER SUMMARY FAILED'

export class selectedCohortDetails  {
  readonly type = ACTIVE_COHORT;
  payload: any;

  constructor(selected: any) {
    this.payload = { ...selected };
  }
}
export class selectedBeliefList {
    readonly type = SELECTED_BELIEFS;
    payload: any;
  
    constructor(selected: any) {
      this.payload = { ...selected };
    }
}
export class baselineMessagesForDrivers {
  readonly type = BASELINE_MESSAGES;
  payload: any;

  constructor(selected: any) {
    this.payload = { ...selected };
  }
}
export class allWritersAndCohorts {
  readonly type = ALL_WRITERS;
  payload: any;

  constructor(selected: any) {
    this.payload = { ...selected };
  }
}
export class allChannelData {
  readonly type = ALL_CHANNELS;
  payload: any;

  constructor(selected: any) {
    this.payload = { ...selected };
  }
}
export class allSelectedBias {
  readonly type = BIAS_SELECTED;
  payload: any;

  constructor(selected: any) {
    this.payload = { ...selected };
  }
}
//new
export class allWritersAndCohortsRequest {
  readonly type = ALL_WRITERS_COHORTS_REQUEST;
}

export class allWritersAndCohortsSuccess {
  readonly type = ALL_WRITERS_COHORTS;
  payload: any;

  constructor(selected: any) {
    this.payload = { ...selected };
  }
}

export class resetTacticsState {
  readonly type = RESET_TACTICS;
}

export class getDriverSummaryRequestAction {
  readonly type = GET_DRIVER_SUMMARY_REQUEST;
}

export class getDriverSummarySuccessAction {
  readonly type = GET_DRIVER_SUMMARY_SUCCESS;
  payload: any;

  constructor(payload: any) {
      this.payload = { ...payload }
  }
}