import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { PagesModule } from './pages/pages.module';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule } from '@angular/forms';
import { addEditFormReducer } from './store/opportunities/reducers/add-edit-form-reducer';
import { appReducer } from './store/app.state';
import { opportunitiesRootReducer } from './store/opportunities/reducers';
import { rootReducer } from './store';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AuthInterceptor } from './services/global/auth.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SplashComponent } from './splash/splash.component';
import { MatIconModule } from '@angular/material/icon';
import { ForbiddenPageComponent } from './forbidden-page/forbidden-page.component';

@NgModule({
  declarations: [
    AppComponent,
    SplashComponent,
    ForbiddenPageComponent,
  ],
  imports: [
    MatIconModule,
    BrowserModule,
    AppRoutingModule,
    PagesModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(rootReducer),
    FormsModule,
    NgxSkeletonLoaderModule.forRoot({ animation: 'progress', theme: { extendsFromRoot: true, background: 'rgb(0,0,0,0.1)', 'border-radius': '5px' } })
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
