import { Action } from '@ngrx/store';
export const CURRENT_CAMPAIGN = 'CURRENT CAMPAIGN';
export const SELECTED_CAMPAIGN = 'SELECTED CAMPAIGN';

export class currentCampaignDetails  {
    readonly type = CURRENT_CAMPAIGN;
    payload: any;
  
    constructor(selected: any) {
      this.payload = { ...selected };
    }
}

export class selectedCampaignDetails {
  readonly type = SELECTED_CAMPAIGN;
  payload: any;

  constructor(selected: any) {
    this.payload = { ...selected };
  }
}
  