import { Action } from '../actions';
import { 
  ACTIVE_COHORT,
  ALL_CHANNELS,
  ALL_WRITERS,
  BIAS_SELECTED,
  BASELINE_MESSAGES,
  SELECTED_BELIEFS,
  allChannelData,
  allWritersAndCohorts,
  baselineMessagesForDrivers,
  selectedBeliefList,
  selectedCohortDetails,
  allSelectedBias,
  ALL_WRITERS_COHORTS,
  ALL_WRITERS_COHORTS_REQUEST,
  RESET_TACTICS,
  GET_DRIVER_SUMMARY_REQUEST,
  GET_DRIVER_SUMMARY_SUCCESS
} from '../actions/actions';

export interface AllWriterCohortsState {
  getWriterCohorts: {};
  loading: boolean;
  loaded: boolean;
}

export const initialStateWritersCohorts: AllWriterCohortsState = {
  getWriterCohorts: {},
  loading: false,
  loaded: false
};

export function allWritersCohortsReducer(state = initialStateWritersCohorts, action: Action): AllWriterCohortsState {
  switch (action.type) {
    case ALL_WRITERS_COHORTS_REQUEST:
      return { ...state, loading: true };
    case ALL_WRITERS_COHORTS:
      return { ...state, getWriterCohorts: action.payload, loading: false, loaded: true };
    case RESET_TACTICS:
        return initialStateWritersCohorts;
    default:
      return state;
  }
}

export interface ActiveCohortsState {
  selectedCohorts: {
    id: string;
    cohortMetaData: any;
    cohortBeliefsData: any;
    beliefsType: any;
    convertedBeliefsData: any;
  }
};

export const initialStateCohorts: ActiveCohortsState = {
  selectedCohorts: {
    id: "",
    cohortMetaData: {},
    cohortBeliefsData: [],
    beliefsType: [],
    convertedBeliefsData: []
  }
};

export function cohortsReducer(state = initialStateCohorts, action: Action): ActiveCohortsState {
  switch (action.type) {
    case ACTIVE_COHORT:
      return {
        ...state,
        selectedCohorts: action.payload,
      };
    default:
      return { ...state };
  }
}

export interface WritersState {
  writers: {
    all: any,
    id: string,
    ischanged: boolean
  }
};


export const initialStateWriters: WritersState = {
  writers: {
    all: [],
    id: "",
    ischanged: true
  }
};


export function writersReducer(state = initialStateWriters, action: Action): WritersState {
  switch (action.type) {
    case ALL_WRITERS:
      return { ...state, writers: action.payload, };
    default:
      return { ...state };
  }
}

export interface BeliefsState {
  selectedBeliefs: {
    ids: any
  }
};

export const initialStateBeliefs: BeliefsState = {
  selectedBeliefs: {
    ids: []
  }
};


export function beliefsReducer(state = initialStateBeliefs, action: Action): BeliefsState {
  switch (action.type) {
    case SELECTED_BELIEFS:
      return { ...state, selectedBeliefs: action.payload, };
    default:
      return { ...state };
  }
}

export interface BaselineMessageState {
  baselineMessages: {
    messages: any
  }
};

export const initialStateBaselineMessage: BaselineMessageState = {
  baselineMessages: {
    messages: []
  }
};

export function baselineMessageReducer(state = initialStateBaselineMessage, action: Action): BaselineMessageState {
  switch (action.type) {
    case BASELINE_MESSAGES:
      return { ...state, baselineMessages: action.payload, };
    default:
      return { ...state };
  }
}

export interface ChannelsState {
  channels: {
    all: any
  }
};

export const initialStateChannels: ChannelsState = {
  channels: {
    all: []
  }
};

export function channelsReducer(state = initialStateChannels, action: Action): ChannelsState {
  switch (action.type) {
    case ALL_CHANNELS:
      return { ...state, channels: action.payload, };
    default:
      return { ...state };
  }
}


export interface AllBiasState {
  bias: {
    all: any
  }
}

export const initialStateAllBias: AllBiasState = {
  bias: {
    all: []
  }
}

export function biasReducer(state = initialStateAllBias, action: Action): AllBiasState {
  switch (action.type) {
    case BIAS_SELECTED:
      return { ...state, bias: action.payload, };
    default:
      return { ...state };
  }
}

export interface DriverSummaryReducerState {
  loading: boolean;
  loaded: boolean;
  getDriverSummaryResponse: any;
}

export const initialStateDriverSummary: DriverSummaryReducerState = {
  loading: false,
  loaded: false,
  getDriverSummaryResponse: {}
}

export function DriverSummaryReducer(state = initialStateDriverSummary, action: Action): DriverSummaryReducerState {
  switch (action.type) {
    case GET_DRIVER_SUMMARY_REQUEST: {
      return { ...state, loading: true }
    }
    case GET_DRIVER_SUMMARY_SUCCESS: {
      const data = action.payload;
      return { ...state, loading: false, loaded: true, getDriverSummaryResponse: data }
    }
    default:
      return state;
  }
}


//selectors
export const getAllWritersCohorts = (st: AllWriterCohortsState) => st.getWriterCohorts;
export const getAllWritersLoading = (st: AllWriterCohortsState) => st.loading;
export const getAllWritersLoaded = (st: AllWriterCohortsState) => st.loaded;
export const getActiveCohorts = (st: ActiveCohortsState) => st.selectedCohorts;
export const getAllWriters = (st: WritersState) => st.writers;
export const getAllBias = (st: AllBiasState) => st.bias;
export const getChannels = (st: ChannelsState) => st.channels;
export const getBaselineMessage = (st: BaselineMessageState) => st.baselineMessages;
export const getBeliefs = (st: BeliefsState) => st.selectedBeliefs;

export const getDriverSummaryLoading = (st: DriverSummaryReducerState) => st.loading;
export const getDriverSummaryLoaded = (st: DriverSummaryReducerState) => st.loaded;
export const getDriverSummary = (st: DriverSummaryReducerState) => st.getDriverSummaryResponse;