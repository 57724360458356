import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionExpiredService {

  private showSessionExpiredPopup = new BehaviorSubject<boolean>(false);

  constructor() { }

  get sessionExpiredPopup() {
    return this.showSessionExpiredPopup.asObservable();
  }

  show() {
    this.showSessionExpiredPopup.next(true);
  }

  hide() {
    this.showSessionExpiredPopup.next(false);
  }
}
