import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addNumberSuffix'
})
export class AddNumberSuffixPipe implements PipeTransform {

  private suffixes = ['', 'K', 'M', 'B', 'T'];

  transform(value: number): string {
    if (value === 0) return '0';
    
    const absValue = Math.abs(value);
    const logValue = Math.log10(absValue);
    const index = Math.floor(logValue / 3);
    const scaledValue = value / Math.pow(10, index * 3);

    return scaledValue.toFixed(1).replace(/\.0$/, '') + this.suffixes[index];
  }

}
