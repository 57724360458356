import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
HighchartsMore(Highcharts);

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/annotations')(Highcharts);

@Component({
  selector: 'app-progressive-chart',
  templateUrl: './progressive-chart.component.html',
  styleUrls: ['./progressive-chart.component.scss']
})
export class ProgressiveChartComponent implements OnInit {

  @Input() chartSeriesData: any = [];
  @Input() fromMonth: number = null;
  @Input() toMonth: number = null;

  axisStyle: any = {
    color: "#2E2E2E",
    fontSize: "1rem",
    fontWeight: "500"
  }
  
  xLabelFontSize: string = '0.88rem';
  yLabelFontSize: string = '0.75rem';

  chart: any = [];
  chartOptions: any = [];

  ngOnInit() {
    if (window.devicePixelRatio === 1.25) {
      this.axisStyle.fontSize = "1.3rem";
      this.yLabelFontSize = "1rem";
      this.xLabelFontSize = "1rem";
    }
    this.renderChart();
  }

  renderChart() {
    const self = this;
    this.chartOptions = {
      credits: {
        enabled: false
      },
      navigation: {
        buttonOptions: {
          enabled: false
        }
      },
      legend: {
        enabled: false
      },
      title: {
        text: undefined
      },
      tooltip: {
        backgroundColor: '#161616',
        style: {
          color: '#FFF',
          fontSize: '0.8em'
        },
        formatter: function () {
          return self.tooltipFormatter(this)
        }
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false
          },
          stickyTracking: false,
          stickyTrackingThreshold: 1,
          events: {
            mouseOut: function () {
              this.chart.tooltip.hide();
            }
          },
          states: {
            inactive: {
              opacity: 1
            }
          },
          lineWidth: 2,
        }
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: 'Months',
          style: this.axisStyle
        },
        lineColor: '#DEDCDE',
        plotBands: [{
          from: this.fromMonth, // Starting point for the colored column
          to: this.toMonth, // Ending point for the colored column
          color: 'rgba(236, 114, 0, 0.07)', // Replace with your desired color
          label: {
            text: `<span class='projected-text pe-1'>Projected </span>`, // Replace with your desired text
            useHTML: true,
          }
        }],
        labels: {
          style: {
            color: '#A7A7A7',
            fontSize: this.xLabelFontSize,
          }
        },
        tickLength: 0,
        tickInterval: 30 * 24 * 3600 * 1000,
      },
      yAxis: {
        min: 0,
        max: 3500,
        title: {
          text: 'TRXs',
          style: this.axisStyle
        },
        lineWidth: 1,
        lineColor: '#DEDCDE',
        gridLineColor: '#E1E1E1',
        gridLineDashStyle: 'Dash',
        labels: {
          style: {
            color: '#A7A7A7',
            fontSize: this.yLabelFontSize,
          },
          formatter: function () {
            if (this.value >= 1000) {
              return Highcharts.numberFormat(this.value, 0, '', ',');
            }
            else {
              return this.value;
            }
          }
        }
      },
      series: this.chartSeriesData
    }
    this.chart = Highcharts.chart('splineChart', this.chartOptions);
  }

  tooltipFormatter(seriesRef: any) {
    var formattedDate = Highcharts.dateFormat('%b, %Y', seriesRef.x);
    var tooltip = `${formattedDate} <br/>`;
    tooltip += `<span class='tooltip-brand-label'>TRx: ${seriesRef.y.toLocaleString("en-us")}</span>`;
    return tooltip;
  }

}
