import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  private exportFlag = new BehaviorSubject<boolean>(false);
  private exportAllFlag = new BehaviorSubject<boolean>(false);

  constructor() { }


  setExportFlag(flag) {
    this.exportFlag.next(flag);
  }

  getExportFlag(): Observable<boolean> {
    return this.exportFlag;
  }

  setExportAllFlag(flag) {
    this.exportAllFlag.next(flag);
  }

  getExportAllFlag(): Observable<boolean> {
    return this.exportAllFlag;
  }

}
