<div class="d-flex justify-content-between">
    <div class="sub-header-front d-flex flex-row align-items-center">
        <h5 class="pt-3 px-3 pb-2 app-name">Brand Performance</h5>
        <mat-icon svgIcon="vertical-barrier" *ngIf="showCampaignName || showRefreshDate"></mat-icon>
        <p class="campaign-name mb-0 px-2" *ngIf="showCampaignName">{{selectedCampaignName}}</p>
        <p class="refresh-date-name mb-0 px-2" *ngIf="showRefreshDate">{{'Last Data Refresh : ' + refreshDate}}</p>
    </div>
    <div class="pe-1-25">
        <mat-icon svgIcon="profile" class="icon-user"></mat-icon>
        <div class="ms-2 competitor-loader" *ngIf="!loaded">
            <ngx-skeleton-loader class="competitor-loader-ngx" count="1" [theme]="{height: '1rem', width:'5rem', margin: '0'}"></ngx-skeleton-loader>
        </div>
        <div class="dropdown">
            <span class="user-label dropbtn">{{userId}}</span>
            <div class="dropdown-content">
                <a (click)="myAccount()">My Account</a>
                <a (click)="logout()">Logout</a>
            </div>
        </div>
    </div>
</div>