import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/global/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent {
  loading: boolean = true;
  userId: string;
  constructor(private authService: AuthService, private router: Router){

   }

  ngOnInit(){
    this.authService.getUserDetails().subscribe({
      next: (response) => {
        this.userId = response?.Response?.UserId;
        if(this.userId)
          sessionStorage.setItem('activeSessionId', this.userId);
        else
          sessionStorage.removeItem('activeSessionId');
        this.loading = false;
        this.router.navigate(['../pages']);
      },
      error: (err) => {
        this.loading = false;
        console.log("Error in getUserDetails", err);
      }
    })
  }
}
