import { NgModule } from '@angular/core';
import * as layout from './';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

const COMPONENT = [
  layout.HeaderComponent,
  layout.FooterComponent,
  layout.PrimaryNavigationComponent
]


@NgModule({
  declarations: [
    ...COMPONENT
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule   
  ], 
  exports: [
    ...COMPONENT
  ]
})
export class LayoutModule { }
