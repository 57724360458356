<mat-card *ngFor="let card of summaryCardData" class="summary-card mb-4">
    <mat-card-header>
        <mat-card-title class="d-flex align-items-center">
            <span class="d-flex align-items-center">
                <mat-icon *ngIf="card.type === 1" svgIcon="brand-summary"></mat-icon>
                <mat-icon *ngIf="card.type === 2" svgIcon="writing-pad-big"></mat-icon>
            </span>
            <span *ngIf="card.type === 1" class="summary-card-title">{{card.cardTitle}} Summary</span>
            <span *ngIf="card.type === 2" class="summary-card-title">{{card.cardTitle}} <span
                    class="current-month-label">(Current Month)</span></span>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div *ngFor="let row of card.summaryRows; let k = index" class="row gx-0 number-chip-div"
            [ngClass]="k === 3 ? 'pb-0 border-0' : ''">
            <div class="row gx-0">
                <div class="col-sm-8 d-flex align-items-center">
                    <div class="summary-number">{{row.quantity | number:'1.0':'en-US' }}</div>
                </div>
                <div class="col-sm-4 chip-div">
                    <div class="summary-chip" [ngClass]="{
                        'chip-green': row.arrowDirection === 'up',
                        'chip-red': row.arrowDirection === 'down',
                    }">
                        <span class="chip-number" [ngClass]="card.type === 2 ? 'p-0' : '' ">{{row.chipText}}</span>
                        <span class="d-flex align-items-center" *ngIf="card.type === 1">
                            <mat-icon *ngIf="row.arrowDirection === 'up'" class="arrow-icon"
                                svgIcon="summary-arrow-up"></mat-icon>
                            <mat-icon *ngIf="row.arrowDirection === 'down'" class="arrow-icon arrow-down"
                                svgIcon="summary-arrow-down"></mat-icon>
                        </span>
                    </div>
                </div>
            </div>

            <div class="row gx-0 pt-2">
                <div class="col-sm-8 d-flex align-items-center">
                    <div class="number-subtitle d-inline-flex">{{row.quantitySubtitle}}</div>
                    <span *ngIf="card.type === 2" class="ps-1 d-inline-flex align-items-center" data-bs-toggle="tooltip" data-bs-placement="top" [title]="row.writerTypeDescription">
                        <mat-icon class="info-icon d-flex align-items-center" svgIcon="info"></mat-icon>
                    </span>
                </div>
                <div class="col-sm-4 chip-div">
                    <div class="metric-subtitle">{{row.chipSubtitle}}</div>
                </div>
            </div>
        </div>

    </mat-card-content>
</mat-card>