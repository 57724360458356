import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private subject = new Subject<any>();
  constructor() { }

  /**
   * send message to banner to show notifications
   * messageType: 'success', 'error', 'warning'
   * messageText
   */
  sendMessage(message){
    this.subject.next(message);
  }

  recieveMessage() : Observable<any> {
    return this.subject.asObservable();
  }
}
