const icons = [
  {
    icon_name: 'current-performance',
    icon_path: 'assets/images/icons/current-performance.svg',
  },
  {
    icon_name: 'opportunities',
    icon_path: 'assets/images/icons/opportunities.svg',
  },
  {
    icon_name: 'measurements',
    icon_path: 'assets/images/icons/measurements.svg',
  },
  {
    icon_name: 'tactics',
    icon_path: 'assets/images/icons/tactics.svg',
  },
  {
    icon_name: 'brand-summary',
    icon_path: 'assets/images/icons/chart-big.svg',
  },
  {
    icon_name: 'summary-arrow-down',
    icon_path: 'assets/images/icons/summary-arrow-down.svg',
  },
  {
    icon_name: 'summary-arrow-up',
    icon_path: 'assets/images/icons/summary-arrow-up.svg',
  },
  {
    icon_name: 'info',
    icon_path: 'assets/images/icons/info.svg',
  },
  {
    icon_name: 'launch-chart',
    icon_path: 'assets/images/icons/launch-chart.svg',
  },
  {
    icon_name: 'stats-big',
    icon_path: 'assets/images/icons/stats-big.svg',
  },
  {
    icon_name: 'breadth',
    icon_path: 'assets/images/icons/breadth.svg',
  },
  {
    icon_name: 'breadth-gray',
    icon_path: 'assets/images/icons/breadth-gray.svg',
  },
  {
    icon_name: 'depth',
    icon_path: 'assets/images/icons/depth.svg',
  },
  {
    icon_name: 'depth-gray',
    icon_path: 'assets/images/icons/depth-gray.svg',
  },
  {
    icon_name: 'writing-pad-big',
    icon_path: 'assets/images/icons/writing-pad-big.svg',
  },
  {
    icon_name: 'reset-big',
    icon_path: 'assets/images/icons/reset-big.svg',
  },
  {
    icon_name: 'add-big',
    icon_path: 'assets/images/icons/add-big.svg',
  },
  {
    icon_name: 'upload',
    icon_path: 'assets/images/icons/upload.svg',
  },
  {
    icon_name: 'filter',
    icon_path: 'assets/images/icons/filter-big.svg',
  },
  {
    icon_name: 'arrow',
    icon_path: 'assets/images/icons/arrow-right2.svg',
  },
  {
    icon_name: 'vertical-barrier',
    icon_path: 'assets/images/icons/Vector 205.svg',
  },
  {
    icon_name: 'grid',
    icon_path: 'assets/images/icons/grid.svg',
  },
  {
    icon_name: 'list',
    icon_path: 'assets/images/icons/list.svg',
  },
  {
    icon_name: 'grid-unselected',
    icon_path: 'assets/images/icons/grid-un-selected.svg',
  },
  {
    icon_name: 'list-unselected',
    icon_path: 'assets/images/icons/list-un-selected.svg',
  },
  {
    icon_name: 'email',
    icon_path: 'assets/images/icons/email.svg',
  },
  {
    icon_name: 'p2p',
    icon_path: 'assets/images/icons/p2p.svg',
  },
  {
    icon_name: 'field',
    icon_path: 'assets/images/icons/field.svg',
  },
  {
    icon_name: 'website',
    icon_path: 'assets/images/icons/computer.svg',
  },
  {
    icon_name: 'stethoscope',
    icon_path: 'assets/images/icons/stethoscope.svg',
  },
  {
    icon_name: 'patients',
    icon_path: 'assets/images/icons/patients.svg',
  },
  {
    icon_name: 'search',
    icon_path: 'assets/images/icons/search.svg',
  },
  {
    icon_name: 'launch',
    icon_path: 'assets/images/icons/launch.svg'
  },
  {
    icon_name: 'copy',
    icon_path: 'assets/images/icons/copy.svg',
  },
  {
    icon_name: 'tactics2',
    icon_path: 'assets/images/icons/tactics2.svg',
  },
  {
    icon_name: 'pencil',
    icon_path: 'assets/images/icons/pencil.svg',
  },
  {
    icon_name: 'trash',
    icon_path: 'assets/images/icons/trash.svg',
  },
  {
    icon_name: 'right-arrow',
    icon_path: 'assets/images/icons/right-arrow.svg',
  },
  {
    icon_name: 'locationpin-time',
    icon_path: 'assets/images/icons/locationpin-time.svg',
  },
  {
    icon_name: 'map',
    icon_path: 'assets/images/icons/map.svg',
  },
  {
    icon_name: 'map',
    icon_path: 'assets/images/icons/map.svg',
  },
  {
    icon_name: 'ellipse-blue',
    icon_path: 'assets/images/icons/ellipse-blue.svg',
  },
  {
    icon_name: 'ellipse-green',
    icon_path: 'assets/images/icons/ellipse-green.svg',
  },
  {
    icon_name: 'ellipse-red',
    icon_path: 'assets/images/icons/ellipse-red.svg',
  },
  {
    icon_name: 'ellipse-yellow',
    icon_path: 'assets/images/icons/ellipse-yellow.svg',
  },
  {
    icon_name: 'ellipse-barriers',
    icon_path: 'assets/images/icons/ellipse-barriers.svg',
  },
  {
    icon_name: 'ellipse-drivers',
    icon_path: 'assets/images/icons/ellipse-drivers.svg',
  },
  {
    icon_name: 'radio',
    icon_path: 'assets/images/icons/radio.svg',
  },
  {
    icon_name: 'down-arrow',
    icon_path: 'assets/images/icons/arrow-down-single.svg',
  },
  {
    icon_name: 'circle-check',
    icon_path: 'assets/images/icons/circle-check.svg',
  },
  {
    icon_name: 'circle-outline',
    icon_path: 'assets/images/icons/smal-circle-check-outline.svg',
  },
  {
    icon_name: 'close',
    icon_path: 'assets/images/icons/close.svg',
  },
  {
    icon_name: 'loader',
    icon_path: 'assets/images/icons/Frame.svg',
  },
  {
    icon_name: 'opportunities-main',
    icon_path: 'assets/images/icons/opportunities-main.svg',
  },
  {
    icon_name: 'ellipse-opportunities-chart',
    icon_path: 'assets/images/icons/ellipse-opportunities-chart.svg',
  },
  {
    icon_name: 'profile',
    icon_path: 'assets/images/icons/profile-big.svg'
  },
  {
    icon_name: 'scroll-up',
    icon_path: 'assets/images/icons/scroll-up.svg'
  },
  {
    icon_name: 'ellipse-gray',
    icon_path: 'assets/images/icons/ellipse-gray.svg'
  },
  {
    icon_name: 'reset-big-gray',
    icon_path: 'assets/images/icons/reset-big-gray.svg'
  },
  {
    icon_name: 'zs-icon-filter',
    icon_path: 'assets/images/icons/zs-icon-filter.svg'
  },
  {
    icon_name: 'add-cohort-tutorial',
    icon_path: 'assets/images/icons/add-cohort-tutorial.svg'
  },
  {
    icon_name: 'edit-cohort-tutorial',
    icon_path: 'assets/images/icons/edit-cohort-tutorial.svg'
  },
  {
    icon_name: 'edit-cohort-tutorial1',
    icon_path: 'assets/images/icons/edit-cohort-tutorial1.svg'
  },
  {
    icon_name: 'reset',
    icon_path: 'assets/images/icons/reset.svg'
  },
  {
    icon_name: 'zs-icon-filter-orange',
    icon_path: 'assets/images/icons/zs-icon-filter-orange.svg'
  },
  {
    icon_name: 'arrow-left-single',
    icon_path: 'assets/images/icons/arrow-left-single.svg'
  },
  {
    icon_name: 'arrow-right-single',
    icon_path: 'assets/images/icons/arrow-right-single.svg'
  },
  {
    icon_name: 'discuss',
    icon_path: 'assets/images/icons/discuss.svg'
  },
  {
    icon_name: 'radio-green',
    icon_path: 'assets/images/icons/radio-green.svg',
  },
  {
    icon_name: 'article',
    icon_path: 'assets/images/icons/article-line 1.svg',
  },
  {
    icon_name: 'arrow-right-single-big',
    icon_path: 'assets/images/icons/arrow-right-single-big.svg',
  },
  {
    icon_name: 'bookmark-filled',
    icon_path: 'assets/images/icons/bookmark-filled.svg',
  },
  {
    icon_name: 'bookmark-outline',
    icon_path: 'assets/images/icons/bookmark-outline.svg',
  },
  {
    icon_name: 'right-arrow-chart',
    icon_path: 'assets/images/icons/right-arrow-chart.svg',
  },
  {
    icon_name: 'zs-icon-mail',
    icon_path: 'assets/images/icons/zs-icon-mail.svg',
  }
];

export default icons;
