import { Component } from '@angular/core';

@Component({
  selector: 'app-redirect-popup',
  templateUrl: './redirect-popup.component.html',
  styleUrls: ['./redirect-popup.component.scss']
})
export class RedirectPopupComponent {

}
