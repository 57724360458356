<div class="px-4 py-4 h-100">
    <form class="d-flex flex-column justify-content-between h-100">
        <div>
            <div class="row mb-2 d-flex flex-row align-items-center justify-content-between">
                <p class="mb-0 add-label fit-content">Publish Campaign</p>
                <span class="close fit-content" mat-dialog-close (click)="cancel()"><mat-icon>close</mat-icon></span>
            </div>
            <hr class="mb-4">
            <div class="pb-4">
                <p class="publish-confirmation">Are you sure you want to publish the campaign?</p>
            </div>
        </div>
        <div class="d-flex flex-row justify-content-end gap-1">
            <button class="cancel-btn" (click)="cancel()"><span class="save-btn-label">No</span></button>
            <button class="save-btn" (click)="publishCampaign()"><span class="save-btn-label">Yes</span></button>
        </div>
    </form>
</div>

