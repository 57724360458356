import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-brand-performance-summary-card',
  templateUrl: './brand-performance-summary-card.component.html',
  styleUrls: ['./brand-performance-summary-card.component.scss']
})
export class BrandPerformanceSummaryCardComponent {
  @Input() summaryCardData: any;
  
}
