import { Component } from '@angular/core';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent {

  messageType: string = '';
  messageText: string = '';
  alert: boolean = false;

  constructor(private messageService: MessageService) {

  }

  ngOnInit(): void {
    this.messageService.recieveMessage().subscribe((message) => {
      this.alert = true;
      this.messageType = message?.messageType;
      this.messageText = message?.messageText;
      if (this.messageType == 'success') {
        window.setTimeout(function () {
          this.alert = false;
        }.bind(this), 3000);
      }
    })
  }

  closeAlert(): void {
    this.alert = false;
  }

}
