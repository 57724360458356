<div class="layout-full-sidenav w-100 h-100">
    <div class="divider bg-standard position-relative">
        <header>
            <app-banner>                
            </app-banner>
            <app-header>
            </app-header>            
        </header>
    </div>
    <div class="nav-container position-relative">
        <app-primary-navigation></app-primary-navigation>
    </div>
    <main>
        <!-- Add the main section of the page here-->
        <router-outlet></router-outlet>
    </main>
</div>