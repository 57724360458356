import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(arr: any[], value: string): any[] {
    if (!arr) { return []; }
    if (!value) { return arr; }

    return arr.filter((item: any) => {
      return Object.keys(item).find((x: any) => {
        if (!!item[x] && item[x].toString().toLocaleLowerCase().includes(value.toLocaleLowerCase()) && x.toString().toLocaleLowerCase() !== 'id') {
          return item;
        }
      })
    });
  }

}
