import { Action } from '../actions';
import {
  CELL_DETAILS,
  GRID_CELLS,
  selectedCellDetails,
  selectedCohortGridDetails,
  SELECTED_OPPORTUNITY,
  selectedOpportunity,
  getCohortSuccessAction,
  GET_COHORT_SUCCESS,
  getCohortRequestAction,
  GET_COHORT_REQUEST,
  RESET_OPPORTUNITIES,
  GET_WRITERS_REQUEST,
  GET_WRITERS_SUCCESS,
  GET_WRITER_HCP_REQUEST,
  GET_WRITER_HCP_SUCCESS 
} from '../actions/actions';

export interface SelectedOpportunityIdReducerState {
  selectedOpportunity: {
    writerId: string;
  }
}

export const initialStateOpportunityId: SelectedOpportunityIdReducerState = {
  selectedOpportunity: {
    writerId: ''
  }
}

export interface CohortsReducerState {
  loading: boolean;
  loaded: boolean;
  getCohortsResponse: any;
}


export const initialStateGetCohorts: CohortsReducerState = {
  loading: false,
  loaded: false,
  getCohortsResponse: {}
}

export interface CohortsGridReducerState {
  selectedCells: {
    xMin: string,
    xMax: string,
    yMin: string,
    yMax: string,
    color: string,
  }
}

export const initialStateGrid: CohortsGridReducerState = {
  selectedCells: {
    xMin: '',
    xMax: '',
    yMin: '',
    yMax: '',
    color: '',
  }
};

export interface CellDetailsReducerState {
  selectedCellDetails: {
    accountType: any[],
    speciality: any[],
    region: any[],
    potentialHcps: string,
    potentialPatients: string
  }
}

export const initialStateCellDetails: CellDetailsReducerState = {
  selectedCellDetails: {
    accountType: [],
    speciality: [],
    region: [],
    potentialHcps: '',
    potentialPatients: ''
  }
}

export interface WritersReducerState {
  loading: boolean;
  loaded: boolean;
  getWritersResponse: any;
}

export const initialStateWriters: WritersReducerState = {
  loading: false,
  loaded: false,
  getWritersResponse: {}
}

export interface WriterHCPReducerState {
  loading: boolean;
  loaded: boolean;
  getWriterHCPResponse: any;
}

export const initialStateWriterHCP: WriterHCPReducerState = {
  loading: false,
  loaded: false,
  getWriterHCPResponse: {}
}

export function CohortsReducer(state = initialStateGetCohorts, action: Action): CohortsReducerState {
  switch (action.type) {
    case GET_COHORT_REQUEST: {
      return { ...state, loading: true }
    }
    case GET_COHORT_SUCCESS: {
      const data = action.payload;
      return { ...state, loading: false, loaded: true, getCohortsResponse: data }
    }
    case RESET_OPPORTUNITIES: {
      return initialStateGetCohorts;
    }
    default:
      return state;
  }
}

export function OpportunityIdReducer(state = initialStateOpportunityId, action: Action): SelectedOpportunityIdReducerState {
  switch (action.type) {
    case SELECTED_OPPORTUNITY:
      return {
        ...state, selectedOpportunity: action.payload,
      };
    case RESET_OPPORTUNITIES: {
      return initialStateOpportunityId;
    }
    default:
      return state;
  }
}

export function cohortsGridReducer(state = initialStateGrid, action: Action): CohortsGridReducerState {
  switch (action.type) {
    case GRID_CELLS:
      return {
        ...state, selectedCells: action.payload,
      };
    default:
      return state;
  }
}

export function cellDetailsReducer(state = initialStateCellDetails, action: Action): CellDetailsReducerState {
  switch (action.type) {
    case CELL_DETAILS:
      return {
        ...state,
        selectedCellDetails: action.payload,
      };
    default:
      return state;
  }
}

export function WritersReducer(state = initialStateWriters, action: Action): WritersReducerState {
  switch (action.type) {
    case GET_WRITERS_REQUEST: {
      return { ...state, loading: true }
    }
    case GET_WRITERS_SUCCESS: {
      const data = action.payload;
      return { ...state, loading: false, loaded: true, getWritersResponse: data }
    }
    default:
      return state;
  }
}

export function WriterHCPReducer(state = initialStateWriterHCP, action: Action): WriterHCPReducerState {
  switch (action.type) {
    case GET_WRITER_HCP_REQUEST: {
      return { ...state, loading: true }
    }
    case GET_WRITER_HCP_SUCCESS: {
      const data = action.payload;
      return { ...state, loading: false, loaded: true, getWriterHCPResponse: data }
    }
    default:
      return state;
  }
}

//selectors
export const getLoading = (st: CohortsReducerState) => st.loading;
export const getLoaded = (st: CohortsReducerState) => st.loaded;
export const getCohorts = (st: CohortsReducerState) => st.getCohortsResponse;
export const getSelectedOpportunity = (st: SelectedOpportunityIdReducerState) => st.selectedOpportunity;
export const getCohortsGrid = (st: CohortsGridReducerState) => st.selectedCells;
export const getCellDetails = (st: CellDetailsReducerState) => st.selectedCellDetails;

export const getWritersLoading = (st: WritersReducerState) => st.loading;
export const getWritersLoaded = (st: WritersReducerState) => st.loaded;
export const getWriters = (st: WritersReducerState) => st.getWritersResponse;

export const getWriterHCPLoading = (st: WriterHCPReducerState) => st.loading;
export const getWriterHCPLoaded = (st: WriterHCPReducerState) => st.loaded;
export const getWriterHCP = (st: WriterHCPReducerState) => st.getWriterHCPResponse;


// export function getCohortsSuccessReducer(state = initialStateGetCohorts, action: getCohortSuccessAction) {
//   switch (action.type) {
//     case GET_COHORT_SUCCESS:
//       return {
//         ...state, loading: false, loaded: true, getCohortsResponse: action.payload
//       };
//     default:
//       return { ...state };
//   }
// }

// export function getCohortsRequestReducer(state = initialStateGetCohorts, action: getCohortRequestAction) {
//   switch (action.type) {
//     case GET_COHORT_REQUEST:
//       return {
//         ...state, loading: true,
//       };
//     default:
//       return { ...state };
//   }
// }